
import React from 'react'

const Section100 = ({ children, className, ...props }) => {
    return (
        <div className={"h-100vh re " + className} {...props}>
            {children}
        </div>
    )
}

export default Section100