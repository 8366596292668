import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
class Header extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			home: false,
		}
	}

	handleLinkClick = e => {
		e.preventDefault()
		this.props.handleClick()
	}
	componentDidMount() {
		if (window.location.pathname === ("/" || "/privacy")) this.setState({ home: true })
	}

	render() {
		const register = () => {
			return (
				<button onClick={this.handleLinkClick} className='w-33p flex flex-e-h p-5-t p-1' id='top-button'>
					<div id='register' className='top-bar-register-button hidden white'>
						<div className='top-bar-register-button-child mar-right-15'>
							<h6 className='text '>{this.props.text}</h6>
						</div>

						<div className='top-bar-register-button-child  '>
							<svg id='arrow' width='18' height='18' viewBox='0 0 18 18' fill='none' className='arrow white-svg' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M12.5455 8.74583L9.73499 11.473L9.73499 5.41553L9.73499 5.31553L9.63499 5.31553L9.05063 5.31553L8.95063 5.31553L8.95063 5.41553L8.95063 11.473L6.14007 8.74583L5.97043 8.58123L5.97043 8.8176L5.97043 9.55471L5.97043 9.59702L6.0008 9.62648L9.27317 12.8017L9.34281 12.8693L9.41245 12.8017L12.6848 9.62648L12.7152 9.59702L12.7152 9.55471L12.7152 8.8176L12.7152 8.58123L12.5455 8.74583Z'
									strokeWidth='0.2'
								/>
								<circle cx='9' cy='9' r='8.5' stroke='#ACAB9A' />
							</svg>
						</div>
					</div>
				</button>
			)
		}
		return (
			<header>
				<div className='top-bar'>
					<div className='loading-bar-wrapper'>
						<div id='loading-bar' className={" loading-bar white " + this.props.loaderClass}></div>
					</div>

					<div className='contnet-top-bar'>
						<Link to='/' name='Home' className='w-33p'>
							<div>
								<svg width='53' height='20' viewBox='0 0 53 20' id='logo' xmlns='http://www.w3.org/2000/svg' className={"inital-white logo  before-load " + this.props.logo}>
									<path d='M0 19.7575V0.787842H8.07815C9.5966 0.787842 10.8114 0.96966 11.7832 1.3333C12.755 1.69693 13.4838 2.18178 13.9697 2.90905C14.4556 3.57572 14.6986 4.48481 14.6986 5.57572C14.6986 6.48481 14.5164 7.3333 14.0912 8.06057C13.666 8.78784 13.1194 9.33329 12.4513 9.81814C13.3624 10.1212 14.0912 10.606 14.6378 11.3939C15.1238 12.1212 15.4274 13.0909 15.4274 14.303C15.4274 15.5151 15.1845 16.4848 14.6378 17.2727C14.0912 18.0606 13.3016 18.6666 12.2083 19.1515C11.115 19.5757 9.71807 19.8181 8.01741 19.8181H0V19.7575ZM7.65298 3.87875H3.27985V8.60602H7.95667C8.98922 8.60602 9.77881 8.4242 10.3862 7.99996C10.9936 7.57572 11.2973 7.03026 11.2973 6.30299C11.2973 5.45451 11.0543 4.84845 10.5077 4.48481C9.90029 4.12117 8.98922 3.87875 7.65298 3.87875ZM7.89594 11.5757H3.27985V16.6666H7.65298C9.11069 16.6666 10.204 16.4848 10.8721 16.0606C11.5402 15.6363 11.9046 14.9697 11.9046 14.0606C11.9046 13.2121 11.601 12.606 10.9328 12.1818C10.3255 11.7575 9.29291 11.5757 7.89594 11.5757Z' />
									<path d='M17.3711 1.63636C17.3711 1.21212 17.5533 0.787879 17.9177 0.484848C18.2822 0.181818 18.7073 0 19.1932 0C19.6791 0 20.1043 0.181818 20.4687 0.484848C20.8332 0.787879 21.0154 1.21212 21.0154 1.63636C21.0154 2.12121 20.8332 2.48485 20.5295 2.84848C20.165 3.15151 19.7399 3.33333 19.254 3.33333C18.7073 3.33333 18.2822 3.15151 17.9785 2.84848C17.5533 2.54545 17.3711 2.12121 17.3711 1.63636ZM17.6748 19.7576V5.69697H20.7724V19.7576H17.6748Z' />
									<path d='M33.5274 19.7576V18.2424C33.2844 18.5455 32.9807 18.8485 32.5555 19.1515C32.1304 19.4545 31.6445 19.697 31.0978 19.8182C30.5512 19.9394 30.0653 20 29.5794 20C28.6076 20 27.6965 19.8182 26.8462 19.5152C25.9958 19.2121 25.267 18.7273 24.6596 18.1212C24.0522 17.5152 23.5663 16.7273 23.2626 15.8182C22.8982 14.9091 22.7767 13.8788 22.7767 12.7879C22.7767 11.7576 22.9589 10.7273 23.3234 9.81818C23.6878 8.90909 24.1737 8.12121 24.8418 7.45455C25.5099 6.78788 26.2388 6.30303 27.0891 5.93939C27.9395 5.51515 28.7898 5.33333 29.7616 5.33333C31.4623 5.33333 32.677 5.69697 33.5274 6.36364V0H36.625V19.7576H33.5274ZM30.126 17.0909C30.4905 17.0909 30.9156 17.0303 31.3408 16.8485C31.766 16.6667 32.2519 16.4242 32.6163 16.0606C33.0415 15.697 33.3451 15.2727 33.5274 14.9091V9.75758C32.7985 8.72727 31.7052 8.24242 30.3082 8.24242C29.3972 8.24242 28.6683 8.42424 28.0002 8.84848C27.3321 9.27273 26.8462 9.81818 26.4817 10.4242C26.1173 11.0909 25.9958 11.8182 25.9958 12.6667C25.9958 14 26.3603 15.0909 27.1499 15.8788C27.8787 16.7273 28.8505 17.0909 30.126 17.0909Z' />
									<path d='M49.7444 15.2727L51.6273 17.1515C50.8984 18.0606 50.0481 18.7879 49.0763 19.2727C48.1045 19.7576 47.0112 20 45.9179 20C44.8246 20 43.7921 19.8182 42.881 19.5151C41.97 19.1515 41.1196 18.6666 40.4515 18.0606C39.7834 17.3939 39.2367 16.6666 38.9331 15.7576C38.5686 14.8485 38.3864 13.8788 38.3864 12.7879C38.3864 11.7576 38.5686 10.7879 38.8723 9.87877C39.176 8.96968 39.6619 8.1818 40.2693 7.51513C40.8767 6.84847 41.6663 6.30301 42.5773 5.93938C43.4884 5.57574 44.5209 5.39392 45.675 5.39392C46.586 5.39392 47.4364 5.57574 48.2867 5.87877C49.0763 6.1818 49.8051 6.60604 50.4125 7.2121C51.0199 7.75756 51.5058 8.48483 51.8095 9.27271C52.1132 10.0606 52.2954 10.9697 52.2954 11.8788C52.2954 12.3636 52.2347 13.0303 52.1132 13.8788H41.5448C41.6663 14.9697 42.1522 15.8182 42.9418 16.3636C43.7921 16.9091 44.7639 17.2121 45.9179 17.2121C46.6468 17.2121 47.3756 17.0303 48.0437 16.7273C48.7726 16.303 49.3192 15.8182 49.7444 15.2727ZM45.432 8.12119C44.3995 8.12119 43.5491 8.36362 42.881 8.90907C42.2129 9.45453 41.7877 10.1818 41.5448 11.0909H48.9548C48.8333 10.1818 48.4689 9.45453 47.8008 8.90907C47.2541 8.42422 46.4646 8.12119 45.432 8.12119Z' />
								</svg>
							</div>
						</Link>
						<div className={"w-33p z-1 flex flex-c-h flex flex-c white o-p-0 tran-2-s " + this.props.middle} id='middle-text'>
							<h6 className='text '>{this.props.textTop}</h6>
						</div>
						{register()}
					</div>
				</div>
			</header>
		)
	}
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
