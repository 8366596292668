import * as React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <div className="footer">
    <div className="flex-row third w-33 two">
      <div className="pad-20 pad-20 ">
        <h5 className="small-footer-text white"> © Dibcorp 2020</h5>
      </div>
      <div className="pad-20">
        <h5 className="small-footer-text white">
          <Link  className="white" to="/privacy">Privacy</Link>
        </h5>
      </div>
      <div className="pad-20 pad-20 display-none">
        
        <div className="small-footer-text white" >News</div>
      </div>
    </div>
    <div className="flex flex-c-h first dibcorp-wrapper w-33">
      
      <div className=" pad-20 f-white">
          <a href="https://www.dibcorp.com.au/" className="logo-dibcorp">
        <svg
          width="62"
          height="17"
          viewBox="0 0 62 17"
          
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
        >
          <path
            d="M2.81909 6.34353C2.29926 6.88505 2.01935 7.63932 2.01935 8.58699C2.01935 9.53466 2.27926 10.2889 2.81909 10.8304C3.33892 11.372 4.05869 11.6427 4.93841 11.6427C5.49823 11.6427 5.99807 11.5074 6.43792 11.2559C6.87778 11.0045 7.21767 10.6564 7.47759 10.1922C7.7375 9.72806 7.85747 9.18653 7.85747 8.58699C7.85747 7.98744 7.7375 7.44592 7.47759 6.98175C7.21767 6.51759 6.87778 6.15013 6.43792 5.89871C5.99807 5.64728 5.49823 5.5119 4.93841 5.5119C4.05869 5.53124 3.33892 5.80201 2.81909 6.34353ZM6.53789 4.46753C7.15769 4.87368 7.57756 5.43454 7.81748 6.11145V0.193359H9.81683V13.2286H7.81748V11.0819C7.57756 11.7588 7.1377 12.3196 6.53789 12.7258C5.93808 13.1319 5.19832 13.3447 4.31861 13.3447C3.47888 13.3447 2.73912 13.1513 2.07933 12.7645C1.41954 12.3777 0.919703 11.8361 0.539826 11.1205C0.179942 10.405 0 9.57334 0 8.58699C0 7.61998 0.179942 6.78835 0.559819 6.07277C0.939697 5.35718 1.43954 4.81566 2.09932 4.42885C2.75911 4.04205 3.49887 3.84865 4.3386 3.84865C5.19832 3.84865 5.93808 4.06139 6.53789 4.46753Z"
          
          />
          <path
            d="M12.356 2.61092C11.6163 2.61092 11.0165 2.03072 11.0165 1.31513C10.9965 0.580205 11.5963 0 12.356 0C13.0958 0 13.6956 0.580205 13.6956 1.29579C13.6956 2.03072 13.0958 2.61092 12.356 2.61092ZM13.3357 13.2287H11.3564V3.96473H13.3357V13.2287Z"
           
          />
          <path
            d="M18.2741 5.91805C17.8343 6.16947 17.4944 6.53693 17.2344 7.0011C16.9745 7.46526 16.8546 8.00678 16.8546 8.60633C16.8546 9.22521 16.9745 9.76674 17.2344 10.2116C17.4944 10.6757 17.8343 11.0238 18.2741 11.2753C18.714 11.5267 19.2138 11.6621 19.7736 11.6621C20.6533 11.6621 21.3531 11.3913 21.8929 10.8498C22.4328 10.3083 22.6927 9.554 22.6927 8.60633C22.6927 7.65866 22.4328 6.92374 21.8929 6.36287C21.3531 5.82135 20.6533 5.53124 19.7736 5.53124C19.2138 5.53124 18.714 5.66662 18.2741 5.91805ZM22.6127 4.42885C23.2725 4.81566 23.7723 5.35718 24.1522 6.07277C24.5321 6.78835 24.712 7.61998 24.712 8.58699C24.712 9.554 24.5321 10.405 24.1522 11.1205C23.7723 11.8361 23.2725 12.3777 22.6127 12.7645C21.9529 13.1513 21.2132 13.3447 20.3734 13.3447C19.5137 13.3447 18.774 13.1319 18.1542 12.7258C17.5344 12.3196 17.1145 11.7588 16.8746 11.0819V13.2286H14.8952V0.193359H16.8746V6.11145C17.1145 5.43454 17.5543 4.87368 18.1542 4.46753C18.774 4.06139 19.5137 3.84865 20.3734 3.84865C21.2132 3.84865 21.9529 4.04205 22.6127 4.42885Z"
           
          />
          <path
            d="M33.0893 4.7963C33.909 5.43453 34.4089 6.28549 34.6088 7.36854H32.5095C32.3895 6.78834 32.1096 6.32417 31.6698 5.99539C31.2299 5.66661 30.6701 5.49255 29.9903 5.49255C29.2305 5.49255 28.5908 5.74397 28.0709 6.26615C27.5711 6.78834 27.3112 7.5426 27.3112 8.56763C27.3112 9.57332 27.5711 10.3469 28.0709 10.8691C28.5708 11.3913 29.2106 11.6621 29.9903 11.6621C30.6701 11.6621 31.2299 11.488 31.6698 11.1592C32.1096 10.8304 32.3895 10.3663 32.5095 9.78606H34.5888C34.3889 10.8691 33.889 11.7394 33.0693 12.3776C32.2496 13.0159 31.2299 13.3447 29.9903 13.3447C29.0706 13.3447 28.2509 13.1513 27.5311 12.7644C26.8113 12.3776 26.2515 11.8361 25.8516 11.1205C25.4518 10.4049 25.2518 9.55398 25.2518 8.58697C25.2518 7.61996 25.4518 6.78834 25.8516 6.07275C26.2515 5.35717 26.8113 4.81564 27.5311 4.42884C28.2509 4.04203 29.0706 3.84863 29.9903 3.84863C31.2499 3.84863 32.2696 4.17742 33.0893 4.7963Z"
            
          />
          <path
            d="M38.6276 5.86006C38.2077 6.09214 37.8478 6.42093 37.5879 6.88509C37.328 7.34925 37.188 7.91012 37.188 8.58702C37.188 9.26393 37.328 9.84413 37.5879 10.3083C37.8478 10.7725 38.1877 11.1012 38.6276 11.3333C39.0474 11.5654 39.5273 11.6621 40.0271 11.6621C40.5269 11.6621 41.0068 11.5461 41.4267 11.3333C41.8465 11.1012 42.2064 10.7725 42.4663 10.3083C42.7262 9.84413 42.8662 9.28327 42.8662 8.58702C42.8662 7.91012 42.7262 7.34925 42.4663 6.88509C42.2064 6.42093 41.8665 6.0728 41.4267 5.86006C41.0068 5.62798 40.5269 5.53128 40.0271 5.53128C39.5273 5.51194 39.0474 5.62798 38.6276 5.86006ZM42.5263 4.42889C43.2661 4.81569 43.8459 5.35722 44.2657 6.0728C44.6856 6.78839 44.8855 7.62002 44.8855 8.58702C44.8855 9.55403 44.6856 10.405 44.2657 11.1206C43.8459 11.8362 43.2661 12.3777 42.5263 12.7645C41.7865 13.1513 40.9468 13.3447 40.0271 13.3447C39.1074 13.3447 38.2677 13.1513 37.5279 12.7645C36.7882 12.3777 36.2083 11.8362 35.7885 11.1012C35.3686 10.3857 35.1487 9.53469 35.1487 8.56768C35.1487 7.60067 35.3686 6.76905 35.7885 6.05346C36.2083 5.33788 36.7882 4.79635 37.5279 4.40955C38.2677 4.02275 39.0874 3.82935 40.0271 3.82935C40.9668 3.84869 41.7865 4.04209 42.5263 4.42889Z"
           
          />
          <path
            d="M49.1642 4.35153C49.764 4.00341 50.4637 3.82935 51.2435 3.82935V5.91808H50.6437C49.784 5.91808 49.0842 6.13082 48.5444 6.53697C48.0245 6.94311 47.7446 7.62002 47.7446 8.56769V13.248H45.7653V3.96473H47.7446V5.7827C48.1045 5.18316 48.5644 4.69965 49.1642 4.35153Z"
          
          />
          <path
            d="M57.0616 11.6621C57.9413 11.6621 58.6411 11.3913 59.1809 10.8304C59.7207 10.2889 59.9806 9.53464 59.9806 8.58697C59.9806 7.6393 59.7207 6.88504 59.1809 6.34351C58.6411 5.80199 57.9413 5.53123 57.0616 5.53123C56.5018 5.53123 56.0019 5.66661 55.5621 5.91803C55.1222 6.16945 54.7823 6.51757 54.5224 6.98174C54.2625 7.4459 54.1425 7.98743 54.1425 8.58697C54.1425 9.18652 54.2625 9.72804 54.5224 10.1922C54.7823 10.6564 55.1222 11.0238 55.5621 11.2753C56.0219 11.546 56.5218 11.6621 57.0616 11.6621ZM57.6814 13.3447C56.8217 13.3447 56.0819 13.1319 55.4621 12.7258C54.8423 12.3196 54.4224 11.7588 54.1825 11.0819V16.9999H52.1832V3.96467H54.1625V6.11143C54.4024 5.43453 54.8423 4.87366 55.4421 4.46752C56.0619 4.06137 56.8017 3.84863 57.6614 3.84863C58.5011 3.84863 59.2409 4.04203 59.9007 4.42884C60.5605 4.81564 61.0603 5.35717 61.4402 6.07275C61.8201 6.78834 62 7.6393 62 8.60631C62 9.57332 61.8201 10.4049 61.4402 11.1205C61.0603 11.8361 60.5605 12.3776 59.9007 12.7644C59.2609 13.1512 58.5211 13.3447 57.6814 13.3447Z"
            
          />
        </svg>
        </a>
      </div>
    </div>
    <div className="flex-row third w-33 flex-e">
    <div className="pad-20 pad-20"> <h5 className="small-footer-text white">
          <a href="https://www.facebook.com/Bide-Newstead-108576140979268" className="small-footer-text white">17-21 Longland Street, Newstead Q 4006</a>
        </h5></div>
        <div className="pad-20 pad-20"> <h5 className="small-footer-text white">
          <a href="https://www.facebook.com/Bide-Newstead-108576140979268" className="small-footer-text white">Facebook</a>
        </h5></div>
        <div className="pad-20 "><h5 className="small-footer-text white">
          <a href="https://www.instagram.com/bidenewstead/" className="small-footer-text white">Instagram</a>
        </h5></div>
    </div>
  </div>
)
export default Footer
