import React, { useState, useRef } from "react"

const inputs = [
	{ type: "text", label: "First Name*" },
	{ type: "text", label: "Surname*" },
	{ type: "email", label: "Email*" },
	{ type: "phone", label: "Phone*" },
	{ type: "text", label: "Budget($/week)*" },
	{ type: "select", label: "What are you enquiring about?", options: ["1-Bedroom Rentals", "2-Bedroom Rentals","3-Bedroom Rentals"] },
]

const RentalForm = () => (
	<Form name='rental'>
		{inputs.map(input => {
			if (input.type === "select") return <Select key={input.label} input={input} />
			return (
				<div key={input.label} className={`input--wrapper`}>
					<label htmlFor={input.label} className='uppercase'>
						{input.label}
					</label>
					<input required type={input.type} name={input.label} className=' w-100 input-rental' />
				</div>
			)
		})}
		<div className='col-span-2'>
			<button type='submit' className=' submit--button text-center pointer'>
				Submit
			</button>
		</div>
	</Form>
)

export default RentalForm

const Select = ({ input }) => (
	<div className='input--wrapper'>
		<label htmlFor={input.label} className='uppercase'>
			{input.label}
		</label>
		<div className='select--wrapper pos-rel'>
			<select required name={input.label} className='w-100 pos-rel input-rental'>
				<option selected disabled value=" ">Please Select</option>
				{input.options.map((option, index) => (
					<option key={index} value={`${option.replace(/-/g, " ")}`}>
						{option}
					</option>
				))}
			</select>
		</div>
	</div>
)

const Form = ({ children, className = "", name = "contact" }) => {
	const [isFormSubmitted, setIsFormSubmitted] = useState(false)
	const formRef = useRef(null)

	const handleSubmit = event => {
		event.preventDefault()
		const myForm = event.target
		const formData = new FormData(myForm)
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: new URLSearchParams(formData).toString(),
		})
			.then(() => {
				setIsFormSubmitted(true)
			})
			.catch(error => alert(error))
	}

	const handleSubmissionClose = e => {
		e.preventDefault()
		setIsFormSubmitted(false)
		formRef.current.reset()
	}

	return (
		<form method='POST' ref={formRef} onSubmit={e => handleSubmit(e)} data-netlify='true' name={name} netlify-honeypot='bot-field' className={"form--wrapper pos-rel " + className}>
			<input type='hidden' name='bot-field' />
			<input type='hidden' name='form-name' value={name} />
			<div className={`form-items ${isFormSubmitted && "op-0"} grid grid-2`}>{children}</div>
			<div className={`form-submission-message z-2 ${isFormSubmitted ? " op-100 pointer-auto" : " op-0 pointer-none"}`}>
				<p className='m0 max-600 text-center mt40'>Thank you for submitting the form we'll be in touch soon</p>
				<button onClick={e => handleSubmissionClose(e)} className='submit--button mt40 cursor-pointer p20'>
					<span>Go Back</span>
				</button>
			</div>
		</form>
	)
}
