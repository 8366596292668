
import React from 'react'

const Loader = ({loaded}) =>{
    return <div className="loader" id="loader">
          <div className="loader-inner">
            <h6
              className="extra-large-George white beforeloading loading-text"
              id="percent"
            >
              {loaded} <span className="percent-sign">%</span>
            </h6>
          </div>
        </div>
}

export default Loader